import $ from "jquery";
//import createRipple from "/js/components/ripple.js";
import prestashop from "prestashop";
import toastr from '../components/toastr'

// Ripple init
let addToCartButton = '[data-cart-icon][data-button-action="add-to-cart"]';

//$('body').on('click', addToCartButton, createRipple);

$('body').on('click', addToCartButton, function () {
    $(this).addClass('loading');
});


function resolveLoader(state, element, message) {
    element.find('.cart-spinner circle').one('animationiteration', function () {
        let button = $(this).closest(addToCartButton);
        button.removeClass('loading').addClass(state);
        message.forEach((message) => {
            toastr[state](message);
        })
        setTimeout(function () {
            button.removeClass(state);
        }, 2000);
    });
}

$(function () {
    prestashop.on("updateCart", function (event) {
        if (
            event &&
            event.reason &&
            typeof event.resp !== "undefined" &&
            event.reason.linkAction !== "add-to-cart"
        ) { return }

        let id = event.reason.idProduct;
        let element = $(`article[data-id-product="${id}"] ${addToCartButton}`);
        let successMessage = []
        successMessage.push(element.data('success-message'));
        if (!event.resp.hasError) {
            resolveLoader('success', element, successMessage);
        } else {
            resolveLoader('error', element, event.resp.errors);
        }
    });
});