import $ from "jquery";

$(function(){
});


const body = document.body;
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

window.addEventListener('scroll', () => {
    const currentScroll = window.pageYOffset;
    const limitScroll = 156;
    if(currentScroll <= 0) {
        body.classList.remove(scrollUp);
        return;
    }

    if(document.querySelectorAll('.cbp-empty-mlink.active').length <= 0) {
        if(currentScroll > lastScroll && currentScroll > limitScroll && !body.classList.contains(scrollDown)) {
            body.classList.remove(scrollUp);
            body.classList.add(scrollDown);
        } else if(currentScroll < lastScroll && body.classList.contains(scrollDown)) {
            body.classList.remove(scrollDown);
            body.classList.add(scrollUp);
        }
        lastScroll = currentScroll;
    }
});